import React from 'react'
import styled from 'styled-components'
import { FaArrowLeft } from 'react-icons/fa'
import { colors, bp } from '../styles/colors'
import { navigate } from 'gatsby'

const BackButton = (props) => {
    return(
        <ButtonContainer onClick={() => navigate(-1)}>
            <Arrow size={22}/>
            <BlackBorder/>
            <GreyBorder/>
        </ButtonContainer>
    )
}

const BlackBorder = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 3rem;
    border: 4px solid ${colors.black};
    position: absolute;
    transition: clip-path ease 0.4s;
    clip-path: polygon(100% 0, 100% 0, 50% 50%, 100% 100%, 100% 100%, 0 100%, 0 0);
    z-index: 10;
`
const GreyBorder = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 3rem;
    border: 4px solid #f2f2f2;
    position: absolute;
    z-index: 1;
    clip-path: polygon(100% 0, 50% 50%, 100% 100%);

`
const Arrow = styled(FaArrowLeft)`
    color: ${colors.black};
    position: absolute;
    transition: all ease 0.4s;
`

const ButtonContainer = styled.div`
    margin-top: 12rem;
    margin-bottom: 5rem;
    width: 4rem;
    height: 4rem;
    border-radius: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(256,256,256,0.5);
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
    &:hover ${BlackBorder} {
        clip-path: polygon(100% 0, 100% 50%, 50% 50%, 100% 50%, 100% 100%, 0 100%, 0 0);
    }
    &:hover ${Arrow} {
        transform: rotate(45deg);
    }

    @media screen and (max-width: ${bp.mobile}) {
        margin-top: -2rem;
    margin-bottom: 3rem;

    }
`

export default BackButton